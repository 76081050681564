

import React, { Suspense } from "react";
import "../css/Gallery.css";

// Utility to introduce delay
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Lazy load with a delay
const lazyWithDelay = (importFunc, delayMs) =>
  React.lazy(() => delay(delayMs).then(() => importFunc()));

// Delayed Components
const Day0Comp = lazyWithDelay(
  () => import("../components/comp-gallery/Day0Items"),
  500
);
const Day1Comp = lazyWithDelay(
  () => import("../components/comp-gallery/Day1Items"),
  1000
);
const Day2Comp = lazyWithDelay(
  () => import("../components/comp-gallery/Day2Items"),
  3000
);
const GuestLectures = lazyWithDelay(
  () => import("../components/comp-gallery/GuestLectures"),
  5000
);

const Gallery = () => {
  return (
    <div className="gallery-container py-4 px-3">
      {/* Title Section */}
      <div className="text-center mb-5 mt-3 px-2">
        <h1 className="display-4 fw-bold border-bottom border-secondary pb-3 mx-auto w-50">
          Gallery
        </h1>
        <h3
          className="fs-5 mt-4 mx-auto text-justify lh-md"
          style={{ hyphens: "auto", maxWidth: "900px", color: "#D3D3D3", fontFamily: "Arial, sans-serif" }}
        >
          Step into the world of Optima through our curated gallery, where
          innovation meets collaboration. Relive the moments that showcase the
          spirit of problem-solving, creativity, and interdisciplinary
          excellence that define this premier tech fest.
        </h3>
      </div>

      {/* Video Section */}
      {/* <div className="container mb-5">
        <div className="ratio ratio-16x9 mx-auto rounded shadow-lg" style={{maxWidth: '720px'}}>
          <iframe
            className="embed-responsive-item rounded"
            src="https://www.youtube.com/embed/MK9qOB40Cic"
            title="Optima Video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div> */}

      {/* Glimpses of Optima */}
      <div className="container text-center my-4">
        <h2 className="fw-bold">
          <span className="d-block d-sm-none">Optima 2023</span>
          <span className="d-none d-sm-block">Glimpses of Optima 2023</span>
        </h2>
      </div>

      {/* Day Sections */}
      {[
        { title: "Day 0", Component: Day0Comp },
        { title: "Day 1", Component: Day1Comp },
        { title: "Day 2", Component: Day2Comp },
      ].map((item, index) => (
        <div className="container mb-5" key={index}>
          <h2 className="gallery-section-heading">{item.title}</h2>
          <Suspense fallback={<LoadingAnimation />}>
            <item.Component />
          </Suspense>
        </div>
      ))}


      {/* Guest Lectures */}
      <Suspense fallback={<LoadingAnimation />}>
        <GuestLectures/>
      </Suspense>
      

    </div>
  );
};

// Reusable Loading Animation
const LoadingAnimation = () => (
  <div className="d-flex justify-content-center align-items-center vh-100">
    <div
      className="spinner-border text-primary"
      role="status"
      style={{ width: "4rem", height: "4rem" }}
    >
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);

export default Gallery;
