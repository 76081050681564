import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../css/Navbar.css";

export default function Navbar() {
	const location = useLocation();

	return (
		<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
			<div
				className="container-fluid"
				style={{
					fontFamily: "Montserrat",
				}}
			>
				<Link className="navbar-brand" to="/">
					<img src="/logo.png" alt="logo" className="logo h-100" />
					Optima 2023
				</Link>
				<button
					className="navbar-toggler"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span className="navbar-toggler-icon"></span>
				</button>
				<div
					className="collapse navbar-collapse"
					id="navbarSupportedContent"
				>
					<ul className="navbar-nav ms-auto mb-2 mb-lg-0">
						<li className="nav-item">
							<Link
								className={
									"nav-link" +
									(location.pathname == "/about"
										? " active"
										: "")
								}
								to="/about"
							>
								About
							</Link>
						</li>
						<li className="nav-item">
							<Link
								className={
									"nav-link" +
									(location.pathname == "/gallery"
										? " active"
										: "")
								}
								to="/gallery"
							>
								Gallery
							</Link>
						</li>
						<li className="nav-item">
							<Link
								className={
									"nav-link" +
									(location.pathname == "/competitions"
										? " active"
										: "")
								}
								to="/competitions"
							>
								Competitions
							</Link>
						</li>
						<li className="nav-item">
							<Link
								className={
									"nav-link" +
									(location.pathname == "/workshops"
										? " active"
										: "")
								}
								to="/workshops"
							>
								Workshops
							</Link>
						</li>
						<li className="nav-item">
							<Link
								className={
									"nav-link" +
									(location.pathname == "/guest-lectures"
										? " active"
										: "")
								}
								to="/guest-lectures"
							>
								Guest Lectures
							</Link>
						</li>

						<li className="nav-item">
							<Link
								className={
									"nav-link" +
									(location.pathname == "/schedule"
										? " active"
										: "")
								}
								to="/schedule"
							>
								Schedule
							</Link>
						</li>
						<li className="nav-item">
							<Link
								className={
									"nav-link" +
									(location.pathname == "/sponsors"
										? " active"
										: "")
								}
								to="/sponsors"
							>
								Sponsors
							</Link>
						</li>
						<li className="nav-item">
							<Link
								className={
									"nav-link" +
									(location.pathname == "/team"
										? " active"
										: "")
								}
								to="/team"
							>
								Team
							</Link>
						</li>
						<li className="nav-item">
							<Link
								className={
									"nav-link" +
									(location.pathname == "/register"
										? " active"
										: "")
								}
								to="/register"
							>
								Register
							</Link>
						</li>
						{/* <li className="nav-item">
              <Link className="nav-link btn btn-dark-blue" to="/register">
                Register
              </Link>
            </li> */}
					</ul>
				</div>
			</div>
		</nav>
	);
}
