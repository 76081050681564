import "../css/Footer.css";
import { Link } from "react-router-dom";
import logo from "../images/home/Artboard 2.png";

export default function Footer() {
  return (
    <div className="Footer-box-container">
      <div className="Footer-box">
        <Link to="/">
          <img src={logo} alt="" />
        </Link>
      </div>
      <div className="Footer-box">
        <h3>Quick Links</h3>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            columnGap: "1rem",
          }}
        >
          {/* <Link to="/">
            <i className="fas fa-arrow-right" /> Home
          </Link> */}
          <Link to="/about">
            <i className="fas fa-arrow-right" /> About Us
          </Link>
          <Link to="/competitions">
            <i className="fas fa-arrow-right" /> Competitions
          </Link>
          <Link to="/workshops">
            <i className="fas fa-arrow-right" /> Workshops
          </Link>
          <Link to="/guest-lectures">
            <i className="fas fa-arrow-right" /> Guest Lectures
          </Link>
          <Link to="/schedule">
            <i className="fas fa-arrow-right" /> Schedule
          </Link>
          <Link to="/sponsors">
            <i className="fas fa-arrow-right" /> Sponsors
          </Link>
          <Link to="/team">
            <i className="fas fa-arrow-right" /> Team
          </Link>
          <Link to="/register">
            <i className="fas fa-arrow-right" /> Register
          </Link>
        </div>
      </div>

      <div className="Footer-box" data-special="true">
        <h3>Contact Info</h3>
        <a href="tel:+917666381808">
          <i className="fas fa-phone" /> +91 7666381808
        </a>
        <a href="mailto:pranav.bhadane.iitkgp@gmail.com">
          <i className="fas fa-envelope" />
          pranav.bhadane.iitkgp@gmail.com
        </a>
        <a href="mailto:saharshagrawal.iitkgp@gmail.com">
          <i className="fas fa-envelope" />
          saharshagrawal.iitkgp@gmail.com
        </a>
        <a href="https://goo.gl/maps/QbvkhBVdd7jr1Gbr6" target="_blank">
          <i className="fa fa-map-marker-alt" /> Department of Industrial and
          Systems Engineering
        </a>
        <div className="Footer-box-icons">
          <a href="https://www.facebook.com/optima.iitkgp" target="_blank">
            <i className="fab fa-facebook-f fa-2x" />
          </a>
          <a href="https://www.instagram.com/optima.iitkgp/" target="_blank">
            <i className="fab fa-instagram fa-2x" />
          </a>
          <a
            href="https://www.linkedin.com/company/optima-iitkgp/"
            target="_blank"
          >
            <i className="fab fa-linkedin-in fa-2x" />
          </a>
        </div>
      </div>      
    </div>
  );
}
